button:hover {
	background-color: bisque;
	background: #c6ebf4;
}

button:focus {
	background-color: orange;
}

.active {
	background-color: cadetblue;
}
