
/* custom code start*/

#EventLog b {
  color: #388e3c;
}

.e-schedule .e-schedule-table > tbody > tr > th,
.e-schedule .e-schedule-table > tbody > tr > td {
  width: 100px !important;
}

 .e-schedule .e-date-header-wrap .e-schedule-table thead {
       display: none;
    }
    
.e-date-header {
  font-size: 12px;
}
.e-title-text {
  font-size: 13px;
}

.e-subject.e-field.e-input {
  font-size: 13px;
}

.e-past-app {
  background-color: chocolate !important;
}

@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";