.card {
	padding: 0.5rem;
	border-radius: 12px;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
	margin: 1rem auto;
	max-width: 98%;
}

.cardshade {
	padding: 1rem;
	background-color: rgb(248, 248, 248);
	margin: 1rem auto;
	max-width: 98%;
}

.table {
	padding: 0.5rem;
	background-color: rgb(255, 255, 255);
	margin: 1rem auto;
	max-width: 98%;
}

.blink_me {
	animation: blinker 2s linear infinite;
}

.blink_down {
	animation: blinker 4s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
