.dropdown-menu,
nav#navbar-main li.nav-item {
	font-size: 12px !important;
	font-weight: bold !important;
	color: white;
}

.p-menuitem-icon {
	color: rgb(255, 98, 0);
}
.p-menubar {
	padding: 0.5rem;
	font-size: 12px !important;
	background: #f9fafb;
	color: #4b5563;
	border: 1px solid #3d6fd4;
	border-radius: 6px;
	margin-left: 0;
}

.p-menuitem-link:hover {
	background-color: #2196f31a !important;
}

.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-content
	.p-menuitem-link {
	padding: 0.5rem 0.7rem;
	-webkit-user-select: none;
	user-select: none;
}

.card {
	background: var(--surface-card);
	padding: 0.2rem;
	border-radius: 5px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
