#main {
	/* position: absolute; */
	top: 20%;
	left: 50%;
	/* margin-top: -100px;
  margin-left: -250px; */
	/* width: 50px; */
	height: 30px;
	border: 2px dashed #d7cfcf;
}
#main p {
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 15px;
	color: #cacaca;
	font-family: Arial;
}
#main input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	outline: none;
	opacity: 0;
}
#main button {
	margin: 0;
	color: #fff;
	background: #16a085;
	border: none;
	width: 508px;
	height: 35px;
	margin-top: -20px;
	margin-left: -4px;
	border-radius: 4px;
	border-bottom: 4px solid #117a60;
	transition: all 0.2s ease;
	outline: none;
}
#main button:hover {
	background: #149174;
	color: #0c5645;
}
#main button:active {
	border: 0;
}

.image-order {
	position: relative;
	align-items: center;
	justify-content: center;
}
.delete-icon {
	cursor: pointer;
}
.drop_zone {
	display: flex;
	height: 30px;
	width: 100%;
	background: white;
	/* background-image: url(""); */
	background-image: url("../assets/upload-img.png");
	background-repeat: no-repeat;
	background-size: 100px;
	background-position: center;
	background-color: #ffffff;
	cursor: pointer;
	border: 2px dashed #ccc;
	border-radius: 6px;
	margin-bottom: 25px;
	background-position: center 28px;
}
.drop_zone > p {
	margin: auto;
}

.previewwrapper {
	/* margin: 5% auto;  */
	max-width: 1100px;
	align-items: center;
	justify-content: center;
}

div.gallery {
	margin: 5px;
	border: 1px solid #ccc;
	float: left;
	width: 90px;
}

div.gallery:hover {
	border: 1px solid #777;
}

div.gallery img {
	width: 100%;
	height: 90px;
}

div.desc {
	padding: 15px;
	text-align: center;
}

.error-msg {
	color: salmon;
}

/* modal - preview modal */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(27, 27, 27, 0.541);
	display: flex;
	align-items: center;
	text-align: center;
}
.overlay img {
	display: block;
	max-width: 60%;
	max-height: 80%;
	margin: 60px auto;
	box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
	display: table;
	margin: 0 auto;
	position: absolute;
	text-decoration: underline;
	top: 20px;
	/* padding-left: 20px; */
	right: auto;
	font-size: 20px;
	color: #ffffffec;
	z-index: 999;
	cursor: pointer;
}
.overlay-arrows_left {
	display: flex;
	background-color: #e4e1e1;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	width: 50px;
	height: 50px;
	left: 0;
	z-index: 999;
}

.overlay-arrows_left svg {
	width: 50px;
	height: 50px;
}

.overlay-arrows_right svg {
	width: 50px;
	height: 50px;
}

.overlay-arrows_right {
	display: flex;
	background-color: #e4e1e1;
	justify-content: space-between;
	position: absolute;
	right: 0;
	width: 50px;
	height: 50px;
	top: 50%;
	z-index: 999;
}
