.machine-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	/* max-height:400px; */
}

.p-organizationchart-node-content {
	padding: 5px !important;
	padding-bottom: 10px !important;
	font-size: small !important;
}
/* p-organizationchart-selectable-node bg-teal-500 text-white border-primary-400 border-round-sm */

#myGrid {
	flex: 1 1 0px;
	width: 100%;
}

/* .machine-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 11px;
  margin-bottom: 5px;
} */

.ag-theme-alpine {
	background-color: transparent;
	margin-top: -10px;
}

ag-paging-panel > span {
	color: black !important;
}

.ag-root-wrapper {
	position: relative !important;
}

/* Give some spaces on top of table for  displaying the page number on top row*/
.ag-root-wrapper-body {
	margin-top: 40px;
}

/* Put page number on top */
.ag-paging-panel {
	position: absolute !important;
	top: 0 !important;
	right: 0 !important;
	border-top: none !important;
}

/* This is used to change the height of AgGridTalbe  */
.ag-theme-alpine .ag-root-wrapper {
	font-size: 14px !important;
	line-height: 14px !important;
	height: 65vh !important;
	width: 100% !important;
}

.ag-center-cols-container {
	min-width: 100%;
}

.card {
	background: var(--surface-card);
	padding: 2rem;
	border-radius: 10px;
	margin-bottom: 1rem;
}
