.button {
display: flex;
height: 40px;
padding: 0;
background-color: #1a83ff;
border: none;
outline: none;
border-radius: 5px;
overflow: hidden;
font-size: 16px;
font-weight: 500;
cursor: pointer;
}

.button:hover,
.button:active {
background-color: #0b89a8;
}

.button __ text,
.button_icon {
display: inline-flex;
align-items: center;
padding: 0 8px;
color: #fff;
height: 100%;
}

.button_icon {
font-size: 1.5em;
background-color: rgba(0, 0, 0, 0.1);
}